/* eslint-env browser */
import React, { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useFormik } from 'formik'
import styled from 'styled-components'
import Message from '@components/Message'
import Box from '@lib/ui/core/Box'
import Typography from '@lib/ui/core/Typography'
import Button from '@lib/ui/core/Button'
import MuiModal from '@material-ui/core/Modal'
import SvgIcon from '@lib/ui/core/SvgIcon'
import { ReactComponent as Close } from '@assets/icons/close.svg'
import urljoin from 'url-join'
import axios from 'axios'
import CreditCardDetail from "@components/CreditCard/Detail"
import ButtonBase from '@lib/ui/core/ButtonBase'
import { hasPath, path, join, useWith, or, identity, assocPath } from 'ramda'
import Spinner from "@components/Spinner"
import Dialog from '@lib/ui/core/DialogWithCloseButton/DialogWithCloseButtonWide'
import { ReactComponent as CloseIcon } from '@assets/icons/close-small.svg'

const CreditCardPopup = styled(({ open, onClose, creditcard, loading, dataEleSection }) => {
  const application_url = creditcard.application_url && creditcard.application_url.url
  return (
    <Dialog title={creditcard.name} application_url={application_url} open={open} onClose={onClose} closeIcon={CloseIcon}>
      {loading ? (
        <Box display="flex" justifyContent="center" my={40}>
          <Spinner />
        </Box>
      ) : (
      <CreditCardDetail creditcard={creditcard} open={open} loading={loading} />
      )}
  </Dialog>
  )
})`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 .9rem;
  outline: 0;
`

export default React.memo(CreditCardPopup)
