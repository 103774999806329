import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '../DialogTitle';
import MuiDialogContent from '../DialogContent';
import Box from '../Box';
import Dialog from '../Dialog';
import IconButton from '../IconButton';
import SvgIcon from '../SvgIcon';
import Typography from '../Typography';
import Button from '@lib/ui/core/Button'

// eslint-disable-next-line prefer-arrow-callback
const DialogWithCloseButtonWide = React.forwardRef(function DialogWithCloseButtonWide(props, ref) {
  const { closeIcon: IconComponent, title, application_url, onClose, children, ...rest } = props;
  return (
    <Dialog onClose={onClose} ref={ref} {...rest}>
      <MuiDialogTitle disableTypography>
        { title && (
          <Box display="flex" lineHeight="40px">
          <Typography style={{"line-height":"40px"}} variant="subtitle1">{title}</Typography>
          <a color="initial" style={{'text-decoration': 'none'}} target="_blank" href={application_url}>
            <Button mx={2}>
              <Typography component="span">立即申請</Typography>
            </Button>
          </a>
          </Box>
        )}
        <Box position="absolute" right={4} top={4}>
          <IconButton aria-label="close" onClick={onClose}>
            {IconComponent ? (
              <SvgIcon component={IconComponent} />
            ) : (
              <SvgIcon component={CloseIcon} />
            )}
          </IconButton>
        </Box>
      </MuiDialogTitle>
      <MuiDialogContent>
        { children }
      </MuiDialogContent>
    </Dialog>
  );
});

DialogWithCloseButtonWide.defaultProps = {
  minWidth: { sm: 800 },
  width: { xs: "100%", sm: "auto" },
}

export default DialogWithCloseButtonWide;
