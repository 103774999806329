import React, { useState, useEffect } from "react"
import { Box } from "@lib/ui/core"
import Link from "@components/Link"
import { parseCreditCard } from "@services/prismic/helpers"
import { searchCreditCards } from "@services/prismic"
import Spinner from "@components/Spinner"
import CardList from "@components/CreditCard/CardList"
import Pagination from "@material-ui/lab/Pagination"
import PaginationItem from "@material-ui/lab/PaginationItem"

const richTextFields = [
  {
    path: ["important_notes"],
    asText: true,
    asHtml: false,
    asRaw: false,
  },
  {
    path: ["highlight"],
    asText: true,
    asHtml: false,
    asRaw: false,
  },
  {
    path: ["detail_exclusive"],
    asText: true,
    asHtml: false,
    asRaw: false,
  },
  {
    path: ["card_details"],
    asText: true,
    asHtml: false,
    asRaw: false,
  },
  {
    path: ["welcome_gift_details"],
    asText: true,
    asHtml: false,
    asRaw: false,
  },
  {
    path: ["welcome_gift_highlight_v2"],
    asText: true,
    asHtml: false,
    asRaw: false,
  },
]

const FilteredResult = ({
  bank_id,
  pageNumber = 1,
  pageSize = 20,
  filterParams,
  location,
}) => {
  const [items, setItems] = useState([])
  const [total, setTotal] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const skeletonCount = 3
  const fetchData = async () => {
    setLoading(true)
    const response = await searchCreditCards(
      bank_id,
      {
        pageSize,
        page: pageNumber,
      },
      {
        parseRichText: richTextFields,
      }
    )
    const { totalCount: newTotal, results } = response
    setTotal(newTotal)
    setItems(results)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [location])

  const totalPage = Math.floor(total / pageSize) + 1

  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent="center" my={5}>
          <Spinner />
        </Box>
      ) : (
        <>
          <CardList
            cards={
              isLoading
                ? [...items, ...Array.from(new Array(skeletonCount))]
                : parseCreditCard(items)
            }
            loading={isLoading}
            fullWidth
          />
          <Box
            textAlign="center"
            spacing={2}
            display="flex"
            justifyContent="center"
          >
            <Pagination
              page={pageNumber}
              count={totalPage}
              renderItem={(item) => (
                <PaginationItem
                  component={Link}
                  to={`/信用卡比較?${filterParams}${
                    item.page === 1 ? "" : `&page=${item.page}`
                  }`}
                  {...item}
                />
              )}
            />
          </Box>
        </>
      )}
    </>
  )
}

export default FilteredResult
