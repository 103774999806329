import React, { useState, useEffect } from 'react'
import { map, path } from 'ramda'
import { linkResolver } from '@utils/link-resolver'
import HeroCarousel from '@components/Carousel/Hero'

const CreditCardCarousel = ({ items }) => {
  const [isMobile, setIsMobile] = useState(true)
  const windowGlobal = typeof window !== 'undefined' && window // eslint-disable-line
  const deviceWidth = windowGlobal ? windowGlobal.document.documentElement.clientWidth : 0

  useEffect(() => {
    setIsMobile(deviceWidth <= 640)
  }, [deviceWidth])

  const slideData = map((item) => ({
    image: path(['image'], item),
    url: item.desktop_url.link_type === 'Document' ? linkResolver(item.desktop_url.document) : path(['desktop_url', 'url'], item),
    target: item.desktop_url.target,
    isBroken: item.desktop_url.isBroken,
  }), items)

  const slideMobileData = map((item) => ({
    image: path(['image', 'thumbnails', 'mobile'], item),
    url: item.mobile_url.link_type === 'Document' ? linkResolver(item.mobile_url.document) : path(['mobile_url', 'url'], item),
    target: item.mobile_url.target,
    isBroken: item.mobile_url.isBroken,
  }), items)

  return isMobile ? <HeroCarousel type="creditCard" items={slideMobileData} aspectRatio="1.5" /> : <HeroCarousel type="creditCard" items={slideData} aspectRatio="2.5" />
}

export default CreditCardCarousel
