import React from 'react'
import { Box, Typography, SvgIcon, Grid } from '@lib/ui/core'
import useResponsive from '@hooks/use-responsive'
import { ReactComponent as Arrow } from '@assets/icons/arrow.svg'
import Link from '@components/Link'
import { linkResolver } from '@utils/link-resolver'
import { path } from 'ramda'
import Card from './Card'
import { ReactComponent as Logo } from '@assets/icons/logo-icon.svg'

const CardList = ({ cards, title, totalCount = 0, fullWidth = false, loading, handleOpen }) => {
  const r = useResponsive()
  return (
    <Box>
      {cards.map((card) => (<Box mb={2.5}><Card card={card} fullWidth loading={card ? false : loading} handleOpen={handleOpen} /></Box>))}
    </Box>
  )
}

export default CardList
