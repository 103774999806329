/* eslint-disable no-return-assign */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import Image from 'gatsby-image'
import Dotdotdot from 'react-dotdotdot'
import { hasPath, path, join, useWith, or, identity } from 'ramda'
import { linkResolver } from '@utils/link-resolver'
import Box from '@lib/ui/core/Box'
import Skeleton from "@lib/ui/core/Skeleton"
import Hidden from '@lib/ui/core/Hidden'
import Typography from '@lib/ui/core/Typography'
import Link from '@components/Link'
import TagList from '@components/Tag/List'
import Grid from "@lib/ui/core/Grid"
import SvgIcon from '@lib/ui/core/SvgIcon'
import Button from '@lib/ui/core/Button'
import { ReactComponent as Logo } from '@assets/icons/logo-icon.svg'
import Content from './Content'
import Section from './Section'
import useResponsive from '@hooks/use-responsive'
import { navigate } from '@reach/router'
import useDeviceDetect from "@hooks/use-device-detect-app"

const renderImage = (images, aspectRatio = 1.67777) => {
  const hasCoverImage = hasPath(["url"], images)
  const isGatsbyImage = hasPath(['thumbnails', 'thumbnail', 'fluid'], images) || hasPath([0, 'image', 'thumbnails', 'thumbnail', 'fluid'], images)
  const thumbnailPath = hasCoverImage ? path(['thumbnails', 'thumbnail', 'fluid'], images) : path([0, 'image', 'thumbnails', 'thumbnail', 'fluid'], images)
  const urlPath = hasCoverImage ? path(["url"], images) : path([0, "image", "url"], images)
  const altPath = hasCoverImage ? path(["alt"], images) : path([0, "image", "alt"], images)
  const width = [60, 120, 240, 360, 480, 600, 720, 840]

  const parsedImage = isGatsbyImage
    ? {
      ...thumbnailPath,
      aspectRatio,
    }
    : {
      src: `${urlPath}&w=${ 
        width[2]
      }&h=${Math.floor(width[2] / aspectRatio)}&fit=crop&q=50`,
      srcSet: join(
        ", ",
        width.map(
          (w) => `${urlPath}&w=${w}&h=${Math.floor(w / aspectRatio)}&fit=crop&q=50 ${w}w`
        )
      ),
      sizes: "",
      aspectRatio,
    }

  return (
    <Image fluid={parsedImage} alt={altPath} />
  )
}

const CreditCard = ({ card, fullWidth = false, loading = false, handleOpen }) => {
  const [isMobile, setIsMobile] = useState(true)
  const [isAndroid, setIsAndroid] = useState(false)
  const windowGlobal = typeof window !== 'undefined' && window // eslint-disable-line
  const deviceWidth = windowGlobal ? windowGlobal.document.documentElement.clientWidth : 0
  useEffect(() => {
    if(typeof Android !== `undefined`)
      setIsAndroid(true)
    setIsMobile(deviceWidth <= 768)
  }, [deviceWidth])


  const {isApp, osName} = useDeviceDetect()
  const rebateLabels = ['本地消費', '餐飲', '本地網購', '海外網購', '海外消費']
  const { card_comparison_image, name, bank, card_image, card_type, card_details, cash_rebate_or_mile_rebate, highlight, highlight_image, important_notes, welcome_gift_details, welcome_gift_highlight_v2, application_url, 
          rebate_1,
          rebate_1_place,
          rebate_2,
          rebate_2_place,
          rebate_3,
          rebate_3_place,
          rebate_4,
          rebate_4_place,
          rebate_5,
          rebate_5_place,
          jetsobee_exclusive,
          detail_exclusive,
          sequence,
          is_sponsored,
          uid,
          prismicId } = card || {}
  const cardUrl = '/信用卡比較/' + uid
  let rebateArray = [
          {id: rebate_1_place === 'By Default' ? 100 : parseInt(rebate_1_place), value: rebate_1, postion: 0},
          {id: rebate_5_place === 'By Default' ? 100 : parseInt(rebate_5_place), value: rebate_5, postion: 1},
          {id: rebate_3_place === 'By Default' ? 100 : parseInt(rebate_3_place), value: rebate_3, postion: 2},
          {id: rebate_4_place === 'By Default' ? 100 : parseInt(rebate_4_place), value: rebate_4, postion: 3},
          {id: rebate_2_place === 'By Default' ? 100 : parseInt(rebate_2_place), value: rebate_2, postion: 4},
          ]
  rebateArray = rebateArray.filter((o) => o.value !== undefined)
  rebateArray.sort((a, b) => (a.id > b.id) ? 1 : -1)
  const isLoadingOr = useWith(or, [identity, identity])(loading)
  const ImageSection = ({ aspectRatio }) => {
    const cardImage = card_comparison_image || card_image
    return (
    <Box borderRadius="0.25rem" clone>
      <img src={cardImage.url} style={{'width': '100%'}} />
    </Box>
  )}
  const r = useResponsive()

  const clickAndroidButton = (url) => {
    if (typeof Android !== `undefined`)
      Android.clickButton(url);
  }

  const DesktopTopSection = () => (
  <>
    <Box display="flex" mb={3}>
    <a color="initial" style={{'cursor': 'pointer'}} to={cardUrl} onClick={() => handleOpen(card)}>
    <Typography variant="h3" component={Dotdotdot} clamp={2} tagName="h3">
      {!loading ? (
        name
      ) : (
        <>
          <Skeleton width="100%" />
          <Skeleton width="60%" />
        </>
      )}
    </Typography>
    </a>
    {is_sponsored &&(<Typography variant="body2" component="span" style={{'margin-left': 'auto', 'min-width': '3rem', 'text-align': 'right', fontWeight: 'bold', color: '#CEA542'}}>贊助</Typography>)}
    </Box>
    <Grid container>
      <Grid item md={3}>
        {!loading ? (
          <Box mr={4} mt={2.5}>
            <a color="initial" style={{'cursor': 'pointer'}} to={cardUrl} onClick={() => handleOpen(card)}><ImageSection aspectRatio={1.6} /></a>
          </Box>
        ) : (
          <Box pb="100%" clone>
            <Skeleton variant="rect" width="100%" />
          </Box>
        )}
      </Grid>
      <Grid item md={9} container>
        <Grid item xs container direction="column" spacing={2} pl={2} pt={2}>
          <Grid item>
            <Grid container item spacing={3}>
            {rebateArray.slice(0, 3).map(({ id, value, postion }) => (
              <Grid item style={{textAlign:'center'}} xs={4}>
                {cash_rebate_or_mile_rebate === 'Cash Rebate' ? (
                <>
                <Typography component="span" style={{fontSize:"1.75rem", fontWeight: "700"}}>{value}%</Typography>
                </>
                ) : (
                <>
                <Typography component="span" style={{fontSize:"1.75rem", fontWeight: "700"}}>${value}</Typography><Typography component="span" style={{fontSize:"1rem"}}>/里</Typography>
                </>
                )}
                <Typography style={{color:"#CEA542", fontWeight: "500"}} variant="body1" clamp={2}>{rebateLabels[postion]}</Typography>
              </Grid>
            )
            )}
            </Grid>
          </Grid>
          <Grid item>
            <Typography style={{"color":"#999999", "padding-left": "30px", "fontSize": "0.75rem"}} variant="body2">*此處顯示最高回贈率，部分或有附帶條件，詳看「信用卡詳情」</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </>
  )

  const MobileTopSection = () => (
  <>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={4}>
        {!loading ? (
          <a style={{'text-decoration': 'none', 'color': 'initial'}} href={cardUrl}><ImageSection aspectRatio={1.6} /></a>
        ) : (
          <Box pb="100%" clone>
            <Skeleton variant="rect" width="100%" />
          </Box>
        )}
      </Grid>
      <Grid item xs={8}>
        <Box display="flex">
        <a style={{'text-decoration': 'none', 'color': 'initial'}} href={cardUrl}>
        <Typography variant="h3" component={Dotdotdot} clamp={2} tagName="h3">
          {!loading ? (
            name
          ) : (
            <>
              <Skeleton width="100%" />
              <Skeleton width="60%" />
            </>
          )}
        </Typography>
        </a>
        {is_sponsored &&(<Typography variant="body2" component="span" style={{'margin-left': 'auto', 'min-width': '3rem', 'text-align': 'right', fontWeight: 'bold', color: '#CEA542'}}>贊助</Typography>)}
        </Box>
      </Grid>
    </Grid>
    <Grid container item spacing={3}>
      {rebateArray.slice(0, 3).map(({ id, value, postion }) => (
        <Grid item style={{textAlign:'center'}} xs={4}>
          {cash_rebate_or_mile_rebate === 'Cash Rebate' ? (
          <>
          <Typography component="span" style={{fontSize:"1.5rem"}}>{value}%</Typography>
          </>
          ) : (
          <>
          <Typography component="span" style={{fontSize:"1.5rem"}}>${value}</Typography><Typography component="span" style={{fontSize:"1rem"}}>/里</Typography>
          </>
          )}
          <Typography style={{color:"#CEA542",fontWeight: "500"}} variant="body1" clamp={2}>{rebateLabels[postion]}</Typography>
        </Grid>
      )
      )}
    </Grid>
    <Box py={2}>
      <Typography style={{"color":"#999999","fontSize": "0.75rem"}} variant="body2">*此處顯示最高回贈率，部分或有附帶條件，詳看「信用卡詳情」</Typography>
    </Box>
  </>
  )

  const DescriptionSection = () => (
  <>
    <Box py={1}>
      { highlight && (
        <Section title="產品特色">
          <Content dangerouslySetInnerHTML={{ __html: highlight.html }} />
        </Section>
      )}
      { highlight_image && (
        <Box maxWidth="988px" mb={{xs:1, sm:2}}>
          {renderImage(highlight_image, highlight_image.dimensions.width / highlight_image.dimensions.height)}
        </Box>
        )
      }
      { jetsobee_exclusive && detail_exclusive && (
        <Box my={{xs:1, sm:2}} bgcolor={is_sponsored ? "#FFFFFF" : "#FEFBE4"} p={2} pb={{xs:1, sm:2}} borderLeft={is_sponsored ? "none" : "6px solid #FBE74D"} borderRadius={is_sponsored ? "20px" : "0px 20px 20px 0px"}>
          <Box display="flex" justifyContent="space-between" m={1} alignItems="center">
            <SvgIcon component={Logo} fontSize="1.5rem" mr={1} />
            <Typography variant="subtitle1" component="h2">
              JetsoBee獨家禮遇
            </Typography>
            <Box ml="auto">
            </Box>
          </Box>
          <Content dangerouslySetInnerHTML={{ __html: detail_exclusive.html }} />
        </Box>
      )}
      { welcome_gift_highlight_v2 && (
        <Section title="迎新獎賞">
          <Content dangerouslySetInnerHTML={{ __html: welcome_gift_highlight_v2.html }} />
        </Section>
      )}
    </Box>
  </>
  )

  return (
    <Box boxShadow={9} borderRadius="1rem" height="100%" overflow="hidden" bgcolor={is_sponsored ? "#FEFBE4" : "background.default"}>
      <Box p={{xs:2, sm:6}}>
        {isMobile ? <MobileTopSection /> : <DesktopTopSection />}
        <DescriptionSection />
        <Grid container spacing={r({ xs: 2, sm: 2, md: 4 })}>
          <Grid item xs={8} md={9}>
          {isAndroid ? (
            <Button fullWidth onClick={() => clickAndroidButton(application_url.url)}>
              <Typography component="span">立即申請</Typography>
            </Button>
          ) : (
            <a color="initial" style={{'text-decoration': 'none'}} target="_blank" href={application_url && application_url.url}>
              <Button fullWidth>
                <Typography component="span">立即申請</Typography>
              </Button>
            </a>
          )}
          </Grid>
          <Grid item xs={4} md={3} >
          {isMobile ? (
            <a href={cardUrl} style={{'text-decoration': 'none'}}>
              <Button fullWidth px={{xs: 0}} bgcolor="#CECECE">
                <Typography component="span">詳情</Typography>
              </Button>
            </a>
            ) : (
              <Button fullWidth px={{xs: 0}} bgcolor="#CECECE" onClick={() => handleOpen(card)}>
                <Typography component="span">詳情</Typography>
              </Button>
            )}
          
          </Grid>
        </Grid>
      </Box>
     </Box>
  )
}

export default CreditCard
