/* eslint-disable camelcase */
import React, { useState, useEffect } from "react"
import { useStoreState, useStoreActions } from "easy-peasy"
import { graphql } from 'gatsby'
import Layout from '@layouts/NoHeaderLayout'
import Container from '@layouts/Container'
import Grid from '@lib/ui/core/Grid'
import SEO from '@components/SEO'
import Box from '@lib/ui/core/Box'
import SvgIcon from '@lib/ui/core/SvgIcon'
import Link from '@components/Link'
import Typography from '@lib/ui/core/Typography'
import CreditCardCarousel from '@components/CreditCard/Carousel'
import { parseCreditCard, parseSiteConfig, prismicClient } from "@services/prismic/helpers"
import { getQueryString, delParam, getQueryArray } from '@utils/url'
import { useAllCreditCard } from '@hooks/use-all-credit-card'
import qs from 'qs'
import Spinner from "@components/Spinner"
import Hidden from "@lib/ui/core/Hidden"
import Divider from "@components/Divider"
import CardList from "@components/CreditCard/CardList"
import { ReactComponent as Logo } from '@assets/icons/logo-icon.svg'
import Pagination from '@material-ui/lab/Pagination'
import PaginationItem from '@material-ui/lab/PaginationItem'
import FilteredResult from "@components/CreditCard/CardList/FilteredResult"
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import { ReactComponent as Sorting } from '@assets/icons/sorting.svg'
import { ReactComponent as FilterSvg } from '@assets/icons/filter-light.svg'
import { searchCreditCards } from "@services/prismic"
import SearchInput from "@components/Search/SearchInput"
import useInfiniteScroll from "@hooks/use-infinite-scroll"
import CreditCardPopup from '@components/CreditCard/Popup'
import urljoin from 'url-join'
import axios from 'axios'
import { hasPath, path, join, useWith, or, includes, identity, assocPath } from 'ramda'
import richTextParser from '@services/prismic/helpers/common/rich-text-parser'
import MenuItem from '@material-ui/core/MenuItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import { linkResolver } from '@utils/link-resolver'
import useDeviceDetect from "@hooks/use-device-detect-app"
import ccpOG from '@assets/images/ccp-og.jpg'
import styled from 'styled-components'

const SelectRoot = styled(Select)`
  &>.MuiSelect-select {
    padding-left: 20px;
    font-size: 1rem
  }
`

const Section = ({
  isLast = false,
  mb = { xs: 0, sm: 8 },
  pb = { xs: 4, sm: 0 },
  children,
  ...rest
}) => (
  <Box mb={mb}>
    <Box pb={pb}>
      <Container {...rest}>{children}</Container>
    </Box>
    {!isLast && (
      <Hidden smUp>
        <Divider />
      </Hidden>
    )}
  </Box>
)

const richTextFields = [
  {
    path: ['important_notes'],
    asText: false,
    asHtml: true,
    asRaw: false,
  },
  {
    path: ['highlight'],
    asText: false,
    asHtml: true,
    asRaw: false,
  },
  {
    path: ['detail_exclusive'],
    asText: false,
    asHtml: true,
    asRaw: false,
  },
  {
    path: ['card_details'],
    asText: false,
    asHtml: true,
    asRaw: false,
  },
  {
    path: ['welcome_gift_highlight_v2'],
    asText: false,
    asHtml: true,
    asRaw: false,
  },
]

const CreditCardsPage = ({ data, location }) => {
  if (!data) 
    return null

  const { siteMetadata } = data.site
  const skeletonCount = 3

  const [selectedBanks, setSelectedBanks] = React.useState([])
  const [orderings, setOrderings] = React.useState('[my.credit_card.last_publication_date desc]')
  const [orderingsArray, setOrderingsArray] = React.useState([])
  const pageSize = 20

  useEffect(() => {
    const selectedBanksURL = getQueryString('banks') ? getQueryString('banks').split(',') : []
    if ( selectedBanks.length === 0 &&  selectedBanksURL.length !== 0)
      setSelectedBanks(selectedBanksURL)
  }, [location]);

  const handleChange = (event) => {
    const { value, checked } = event.target
    let updatedList = [...selectedBanks]
    if (checked) {
      setSelectedBanks([...selectedBanks, value])
    } else {
      setSelectedBanks(selectedBanks.filter((e) => e !== value));
    }
  }

  const handleSorting = (event) => {
    setOrderings(event.target.value)
    setOrderingsArray(event.target.value)
  }

  useEffect(() => {
    if (orderings !== '[my.credit_card.last_publication_date desc]' || selectedBanks.length > 0) {
      firstFetchData()
      if( !isMobile )
        typeof window !== `undefined` && window.scrollTo({ top: 450, behavior: 'smooth' })
      window.history.pushState('CreditCard', 'Comparison', '/信用卡比較/?banks=' + selectedBanks.join(','))
    }
  }, [selectedBanks, orderingsArray]);


  const docs = data.allPrismicCreditCard
  const { currentPage, pageCount } = data && data.allPrismicCreditCard && data.allPrismicCreditCard.pageInfo
  const allBanks = data.allPrismicBank.nodes

  const creditCardsCarousel = data.siteConfig.nodes[0].data.card_comparison_page_carousel

  const creditcardsNodes = docs.nodes
  const sponsoredCreditCards = parseSiteConfig(data.siteConfig.nodes[0].data.card_comparison_sponsor_cards)
  sponsoredCreditCards.filter((o) => o.display === true).forEach(function(sponsorItem){
    sponsorItem.is_sponsored = true
  });
  const creditCards = currentPage === 1 ? [...sponsoredCreditCards, ...creditcardsNodes] : creditcardsNodes

  const [items, setItems] = useState([])
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [showSorting, setShowSorting] = useState(false)
  const [openShowSorting, setOpenShowSorting] = useState(false)

  const [isMobile, setIsMobile] = useState(true)
  const windowGlobal = typeof window !== 'undefined' && window // eslint-disable-line
  const deviceWidth = windowGlobal ? windowGlobal.document.documentElement.clientWidth : 0

  useEffect(() => {
    setIsMobile(deviceWidth <= 768)
  }, [deviceWidth])

  const getCurrentPage = () => Math.floor(items.length / pageSize)

  const firstFetchData = async () => {
    setIsLoading(true)
    const response = await searchCreditCards(selectedBanks, {
      pageSize,
      page: 1,
      orderings
    },
    {
      parseRichText: richTextFields,
    })
    const { totalCount: newTotal, results } = response
    setTotal(newTotal)
    setItems(results)
    setIsLoading(false)
    return response
  }


  const fetchData = async () => {
    setIsLoading(true)
    const response = await searchCreditCards(selectedBanks, {
      pageSize,
      page: getCurrentPage() + 1,
      orderings
    },
    {
      parseRichText: richTextFields,
    })
    const { totalCount: newTotal, results } = response
    setTotal(newTotal)
    setItems((prev) => [...prev, ...results])
    setIsLoading(false)
    return response
  }

  const hasNextPage = () => items.length < total

  const [anchorRef, { loading, setTrigger }] = useInfiniteScroll({
    inViewOptions: {
      rootMargin: "600px 0px",
    },
    hasNextPage,
    next: fetchData,
  })

  useEffect(() => {
    setTrigger(true)
  }, [])

  const itemsWithSponsored = currentPage === 1 ? [...sponsoredCreditCards, ...items] : items

  const banksArray = selectedBanks.length > 0 || orderings !== '[my.credit_card.last_publication_date desc]' ? itemsWithSponsored : creditCards

  const [open, setOpen] = useState(false)
  const [selectCard, setSelectCard] = useState('')
  const [creditcard, setCreditcard] = useState({})

  let cardData = []
  const fetchCardData = async (selectCardId) => {
    setIsLoading(true)
    const url = urljoin(process.env.GATSBY_API_URL, '/creditcards/', selectCardId)
    const response = await axios.get(url)
    cardData = response.data.data
    richTextFields.forEach(({ path: richTextPath, asText, asHtml, asRaw }) => {
        const old = path(richTextPath, cardData)
        cardData = assocPath(richTextPath, richTextParser(old, { asText, asHtml, asRaw }), cardData)
      })
    setCreditcard(cardData)
    setIsLoading(false)
  }

  const handleOpen = (creditcard) => {
    console.log(creditcard)
    const cardUrl = '/信用卡比較/' + creditcard.uid
    setCreditcard(creditcard)
    setOpen(true)
    window.history.pushState(creditcard.prismicId, creditcard.name, cardUrl)
  };

  const handleClose = () => {
    setOpen(false)
    window.history.pushState('CreditCard', 'Comparison', '/信用卡比較')
  };

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200
      },
    },
  }

  const handleCloseSorting = () => {
    setOpenShowSorting(false);
  }

  const handleOpenSorting = () => {
    setOpenShowSorting(true)
  }

  const {isApp} = useDeviceDetect()

  return (
    <Layout headerTitle="信用卡比較" type="credit_card">
      <SEO
        title="信用卡比較"
        image={urljoin(siteMetadata.siteUrl, ccpOG)}
      />
      <Container>
        <Box display="none" position="relative" mt="-1.5rem" mb={{ xs: 2.5, sm: 4 }}>
          <SearchInput height="10rem" />
        </Box>
      </Container>
      {!isApp &&(
      <Container px={{ xs: 0, sm: 3 }} mt={{ xs: 0, sm: 1 }}>
        <Box mb={{ xs: 0, sm: 2.5 }} data-ele-section="card-comparison-carousel">
          <CreditCardCarousel items={creditCardsCarousel} />
          <Hidden smUp>
            <Divider />
          </Hidden>
        </Box>
      </Container>
      )}
      <CreditCardPopup open={open} onClose={handleClose} creditcard={creditcard} loading={isLoading} />
      <Container>
        <Box display="flex" justifyContent="space-between" m={2} alignItems="center">
          <SvgIcon component={Logo} fontSize="1.5rem" mr={1} />
          <Typography variant="subtitle1" component="h2">
            信用卡比較
          </Typography>
          <Box ml="auto" display="flex" pt={1}>
          {isMobile &&(
            <>
            <Box onClick={() => setOpenShowSorting(prev => !prev)} display="flex" mb={1} mr={2} color="#CEA542">
              <SvgIcon component={Sorting} fontSize="1.5rem" mr={1} />
              <Typography variant="body2" component="h3">排序</Typography>
            </Box>
            <Box onClick={() => setShow(prev => !prev)} style={{ cursor: 'pointer' }} display="flex" mb={1} color="#CEA542">
              <SvgIcon component={FilterSvg} fontSize="1.5rem" mr={1} />
              <Typography variant="body2" component="h3">篩選</Typography>
            </Box>
            </>
          )}
          </Box>
        </Box>
        <Grid container spacing={{xs: 0, md: 2}} mb={{ xs: 3.5, sm: 7.5 }} data-ele-section="card-comparison-list">
          <Grid item xs={12} sm={12} md={3} component={Box} position={{ md: 'sticky' }} top={{ md: '60px' }} height={{ md: '100vh' }}>
            <Box px={2} py={{ xs: 0, sm: 2 }} alignItems="center">
              <Box display={{ xs: "none", sm: "flex" }} mb={1} mr={2}>
                <Typography variant="body2" component="h3">排序</Typography>
              </Box>
              {(!isMobile || openShowSorting) && 
              (
              <Box border={1} borderColor="#D1D1D1" borderRadius={3}>
              <FormControl fullWidth="true">
                <SelectRoot disableUnderline 
                  onChange={handleSorting} 
                  open={openShowSorting}
                  onClose={handleCloseSorting}
                  onOpen={handleOpenSorting}
                  defaultValue="[my.credit_card.data___publish_start_date desc]" 
                  id="grouped-native-select" 
                  >
                <MenuItem value="[my.credit_card.data___publish_start_date desc]">熱門</MenuItem>
                <MenuItem value="[my.credit_card.cash_rebate_or_mile_rebate,my.credit_card.rebate_1 desc]">本地消費(現金)</MenuItem>
                <MenuItem value="[my.credit_card.cash_rebate_or_mile_rebate,my.credit_card.rebate_2 desc]">海外消費(現金)</MenuItem>
                <MenuItem value="[my.credit_card.cash_rebate_or_mile_rebate,my.credit_card.rebate_3 desc]">本地網購(現金)</MenuItem>
                <MenuItem value="[my.credit_card.cash_rebate_or_mile_rebate,my.credit_card.rebate_4 desc]">海外網購(現金)</MenuItem>
                <MenuItem value="[my.credit_card.cash_rebate_or_mile_rebate,my.credit_card.rebate_5 desc]">餐飲(現金)</MenuItem>
                <ListSubheader><Divider bgcolor="#D1D1D1" my={{ xs: 1, sm: 1.5 }} /></ListSubheader>
                <MenuItem value="[my.credit_card.cash_rebate_or_mile_rebate desc,my.credit_card.rebate_1 desc]">本地消費(里數)</MenuItem>
                <MenuItem value="[my.credit_card.cash_rebate_or_mile_rebate desc,my.credit_card.rebate_2 desc]">海外消費(里數)</MenuItem>
                <MenuItem value="[my.credit_card.cash_rebate_or_mile_rebate desc,my.credit_card.rebate_3 desc]">本地網購(里數)</MenuItem>
                <MenuItem value="[my.credit_card.cash_rebate_or_mile_rebate desc,my.credit_card.rebate_4 desc]">海外網購(里數)</MenuItem>
                <MenuItem value="[my.credit_card.cash_rebate_or_mile_rebate desc,my.credit_card.rebate_5 desc]">餐飲(里數)</MenuItem>
                </SelectRoot>
              </FormControl>
              </Box>
              )}
            </Box>
            <Box px={2} py={{ xs: 0, sm: 2 }}>
              {!isMobile &&(<Divider bgcolor="#D1D1D1" mb={{ xs: 1, sm: 2 }} />)}
              <Box style={{ cursor: 'pointer' }} display={{ xs: "none", sm: "flex" }} mb={1}>
                <Typography variant="body2" component="h3">篩選</Typography>
              </Box>
              {(!isMobile || show) && 
              (<Box>
                <FormGroup row={show &&("true")}>
                  {allBanks.map((bank)=>{
                    if (selectedBanks.includes(bank.prismicId))
                      return (<FormControlLabel value={bank.prismicId} onChange={handleChange} control={<Checkbox defaultChecked />} label={bank.data.name} />)
                    else
                      return (<FormControlLabel value={bank.prismicId} onChange={handleChange} control={<Checkbox />} label={bank.data.name} />)
                  }
                  )}
                </FormGroup>
              </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            {isLoading ? (
              <Box display="flex" justifyContent="center" my={30}>
                <Spinner />
              </Box>
            ) : (
            <>
              {(selectedBanks.length > 0 && total > 0) && (
                <Typography my={1}>找到{total}個信用卡</Typography>
              )}
              {(selectedBanks.length > 0 && total === undefined) && (
                <Typography my={1}>沒有找到信用卡</Typography>
              )}
              <CardList cards={ isLoading ? [...banksArray, ...Array.from(new Array(skeletonCount))] : parseCreditCard(banksArray)}
                  loading={loading} 
                  handleOpen={handleOpen} />
              {(selectedBanks.length === 0 && orderings === '[my.credit_card.data___publish_start_date desc]') ? (
                <Box textAlign="center" spacing={2} display="flex" justifyContent="center">
                <Pagination
                  page={currentPage}
                  count={pageCount}
                  renderItem={(item) => (
                    <PaginationItem
                      component={Link}
                      to={`/信用卡比較/${item.page === 1 ? '' : `${item.page}`}`}
                      {...item}
                    />
                  )}
                />
              </Box>
              ) : (
              <Box ref={anchorRef} />
              )}
            </>
            )}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default CreditCardsPage

export const query = graphql`
  query CreditCardsListQuery($limit: Int!, $skip: Int!, $today: Date) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    siteConfig: allPrismicSiteConfig {
      nodes {
        data {
          card_comparison_sponsor_cards {
            data: credit_card {
              url
              uid
              id
              isBroken
              document {
                ... on PrismicCreditCard {
                  prismicId
                  last_publication_date
                  uid
                  data {
                    application_url {
                      url
                    }
                    bank {
                      document {
                        ... on PrismicBank {
                          id
                          uid
                          prismicId
                          data {
                            name
                            card_comparison_page_filter
                          }
                        }
                      }
                    }
                    card_comparison_image {
                      alt
                      url
                      fixed(width: 300) {
                        ...GatsbyPrismicImageFixed_noBase64
                      }
                    }
                    card_details {
                      html
                      raw
                      text
                    }
                    card_image {
                      alt
                      url
                      fixed(width: 300) {
                        ...GatsbyPrismicImageFixed_noBase64
                      }
                    }
                    card_type
                    cash_rebate_or_mile_rebate
                    detail_exclusive {
                      html
                      raw
                      text
                    }
                    display
                    display_on_offer_page
                    highlight {
                      html
                      raw
                      text
                    }
                    highlight_image {
                      alt
                      url
                      dimensions {
                        width
                        height
                      }
                      fluid(maxWidth: 988) {
                        ...GatsbyPrismicImageFluid_noBase64
                      }
                    }
                    important_notes {
                      html
                      raw
                      text
                    }
                    jetsobee_exclusive
                    minimum_annual_salary
                    name
                    rebate_1
                    rebate_1_place
                    rebate_2
                    rebate_2_place
                    rebate_3
                    rebate_3_place
                    rebate_4
                    rebate_4_place
                    rebate_5
                    rebate_5_place
                    sequence
                    welcome_gift_details {
                      left_column
                      right_column
                    }
                    welcome_gift_highlight_v2 {
                      html
                      raw
                      text
                    }
                  }
                }
              }
            }
          }
          card_comparison_page_carousel {
            image {
              fluid(maxWidth: 1300) {
                ...GatsbyPrismicImageFluid_noBase64
              }
              thumbnails {
                mobile {
                  fluid(maxWidth: 600) {
                    ...GatsbyPrismicImageFluid_noBase64
                  }
                }
              }
            }
            desktop_url {
              __typename
              url
              id
              type
              document {
                ... on PrismicCreditCard {
                  id
                  uid
                  type
                  url
                }
              }
              isBroken
              link_type
              target
            }
            mobile_url {
              __typename
              url
              id
              type
              document {
                ... on PrismicCreditCard {
                  id
                  uid
                  type
                  url
                }
              }
              isBroken
              link_type
              target
            }
          }
        }
      }
    }
    allPrismicBank(filter: {data: {card_comparison_page_filter: {eq: true}}}) {
      nodes {
        prismicId
        data {
          name
        }
        uid
      }
    }
    allPrismicCreditCard(
      sort: { fields: data___publish_start_date, order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        data: {
          publish_start_date: {
            lte: $today
          },
          publish_end_date: {
            gte: $today
          },
          display: {
            eq: true
          },
        }
      }
    )  {
        nodes {
          prismicId
          last_publication_date
          uid
          data {
            application_url {
              url
            }
            bank {
              document {
                ... on PrismicBank {
                  id
                  uid
                  prismicId
                  data {
                    name
                    card_comparison_page_filter
                  }
                }
              }
            }
            card_comparison_image {
              alt
              url
              fixed(width: 300) {
                ...GatsbyPrismicImageFixed_noBase64
              }
            }
            card_details {
              html
              raw
              text
            }
            card_image {
              alt
              url
              fixed(width: 300) {
                ...GatsbyPrismicImageFixed_noBase64
              }
            }
            card_type
            cash_rebate_or_mile_rebate
            detail_exclusive {
              html
              raw
              text
            }
            display
            display_on_offer_page
            highlight {
              html
              raw
              text
            }
            highlight_image {
              alt
              url
              dimensions {
                width
                height
              }
              fluid(maxWidth: 988) {
                ...GatsbyPrismicImageFluid_noBase64
              }
            }
            important_notes {
              html
              raw
              text
            }
            jetsobee_exclusive
            minimum_annual_salary
            name
            rebate_1
            rebate_1_place
            rebate_2
            rebate_2_place
            rebate_3
            rebate_3_place
            rebate_4
            rebate_4_place
            rebate_5
            rebate_5_place
            sequence
            welcome_gift_details {
              left_column
              right_column
            }
            welcome_gift_highlight_v2 {
              html
              raw
              text
            }
          }
        }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`
